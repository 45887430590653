import { createSlice } from '@reduxjs/toolkit';
import { ContactsActions } from '../Contacts/contacts.action';
import { FormAction } from './formbuilder.actions';

interface FormDataState {
    data: {
        createForm: any;
        formListing: any;
        formListingPagination: any;
        addContactsDropDownList: any;
        formFieldsData: any;
        formSettings: any;
        defaultFieldsId: any;
        formDetails: string | undefined | null;
        formConfig: any;
        defaultValue: any;
        optionsToShow: any;
        rowCount: any;
        column: any;
        pageCount: number;
        droppedFieldsIds: any;
        contactFields: any;
        maxPageLimit: number;
        pageFields: any;
        deviceForPreview: string;
        customPropertiesPreview: boolean;
        isFieldEdit: boolean;
        fieldObj: {
            name: string;
            label: string;
            type: string;
            row: number;
            column: number;
            is_dependant?: boolean;
            dependant_field_id?: string;
            custom?: boolean;
            visible?: boolean;
            id?: string;
            required?: boolean;
        },
        contactProperties: any
    };
    error: string;
    loading: boolean;
}

const initialState: FormDataState = {
    data: {
        createForm: null,
        formListing: [],
        formFieldsData: [],
        formSettings: {},
        defaultFieldsId: {},
        formDetails: "",
        formConfig: {},
        defaultValue: {},
        optionsToShow: [],
        formListingPagination: null,
        addContactsDropDownList: [],
        contactFields: [],
        rowCount: null,
        column: null,
        pageCount: 1,
        droppedFieldsIds: [],
        maxPageLimit: 10,
        pageFields: [],
        isFieldEdit: false,
        deviceForPreview: "desktop",
        customPropertiesPreview: false,
        fieldObj: {
            name: "",
            label: "",
            type: "",
            row: 0,
            column: 0
        },
        contactProperties: []
    },
    error: "",
    loading: false,
}

const formBuilderSlice = createSlice({
    name: 'formBuilder',
    initialState,
    reducers: {
        addFormFields(state: any, action: any) {
            state.data.formFieldsData = action?.payload || [];
        },
        addSettings(state: any, action: any) {
            state.data.formSettings = { ...state.data.formSettings, ...action?.payload } || {};
        },
        addDefaultValue: (state: any, action: any) => {
            state.data.defaultValue = { ...state.data.defaultValue, ...action?.payload } || {};
        },
        addDefaultFieldsId: (state: any, action: any) => {
            state.data.defaultFieldsId = { ...state.data.defaultFieldsId, ...action?.payload } || {};
        },
        clearFormStore: (state: any) => {
            state.data.formSettings = initialState.data.formSettings;
            state.data.formFieldsData = initialState.data.formFieldsData;
            state.data.formDetails = initialState.data.formDetails;
            state.data.isFieldEdit = initialState.data.isFieldEdit;
        },
        addOptionsToShow: (state: any, action: { payload: any }) => {
            const key = action?.payload?.values?.name;
            const value = action?.payload?.options;
            const field: any = {
                name: key,
                values: value?.map((item: any) => item?.id) || [],
                check_dependency: false
            }
            const index = state.data.optionsToShow.findIndex((obj: any) => obj.name === key);
            if (index !== -1) {
                state.data.optionsToShow[index] = field;
                return;
            }
            state.data.optionsToShow = [...state.data.optionsToShow, field] || [];
        },
        saveConditionLogicInEditFlow: (state: any, action: any) => {
            state.data.optionsToShow = action?.payload || [];
        },
        saveContactFields: (state: any, action: any) => {
            state.data.contactFields = action?.payload || [];
        },
        saveFormConfig: (state: any, action: any) => {
            state.data.formConfig = action?.payload || {};
        },
        removeOptionsToShow: (state: any, action: any) => {
            const key = action?.payload;
            state.data.optionsToShow = state.data.optionsToShow.filter((item: any) => item?.name !== key) || [];
        },
        saveFormName: (state: any, action: { payload: any }) => {
            state.data.formDetails = action?.payload;
        },
        deletePropertyFromDefaultObject: (state: any, action: any) => {
            const propertyName = action.payload;
            delete state.data.defaultFieldsId[propertyName];
            delete state.data.defaultValue[propertyName];
        },
        addRow(state: any, action: any) {
            state.data.formFieldsData = [...state.data.formFieldsData, action.payload] || []
        },
        removeRow(state: any, action: any) {
            state.data.formFieldsData = state?.data?.formFieldsData?.filter((row: any, index: any) => index !== action?.payload)
        },
        addFieldToRows(state: any, action: { payload: { row: any, column: any, newFormField: any, } }) {
            const rowArray = state.data.formFieldsData?.[action.payload.row];
            state.data.formFieldsData[action.payload.row][action.payload.column] = { ...rowArray?.[action.payload.column], ...action.payload.newFormField }
        },
        saveUpdatedFieldsPositions(state: any, action: any) {
            state.data.formFieldsData = action.payload;
        },
        setAllFields(state: any, action: any) {
            state.data.formFieldsData = action.payload;
        },
        removeFieldFromRow(state: any, action: { payload: { width: any, row: any, column: any } }) {
            state.data.formFieldsData[action.payload.row][action.payload.column] = { width: action.payload.width };
        },
        saveDroppedFieldsIds(state: any, action: any) {
            if (typeof action.payload !== 'undefined' && action.payload !== null) {
                if (!state.data.droppedFieldsIds.includes(action.payload)) {
                    state.data.droppedFieldsIds = [...state.data.droppedFieldsIds, action.payload]
                }
            }
        },
        deleteDroppedFieldsIds(state: any, action: any) {
            state.data.droppedFieldsIds = state.data.droppedFieldsIds.filter((item: any) => item !== action.payload)
        },
        removePageRows(state: any, action: { payload: any }) {
            state.data.pageFields = action.payload
        },
        addFormPage(state: any, action: any) {
            state.data.pageCount = action.payload
        },
        editField(state: any, action: { payload: boolean }) {
            state.data.isFieldEdit = action.payload
        },
        setPreviewDevice(state: any, action: { payload: string }) {
            state.data.deviceForPreview = action.payload
        },
        editingFieldObject(state: any, action: { payload: { label: string, type: string, row: number, column: number } }) {
            state.data.fieldObj = action.payload;
        },
        storepageFields(state: any, action: any) {
            state.data.pageFields = action.payload
        },
        setContactProperties(state: any, action: { payload: any }) {
            state.data.contactProperties = action.payload
        },
        setCustomPropertiesPreview(state: any, action: { payload: boolean }) {
            state.data.customPropertiesPreview = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            // Form Listing
            .addCase(FormAction.GetFormListing.pending, (state, action) => {
                state.data.formListing = [];
                state.loading = true;
            })
            .addCase(FormAction.GetFormListing.fulfilled, (state, action) => {
                state.data.formListing = action?.payload?.data?.forms || [];
                state.data.formListingPagination = action?.payload?.data?.pagination || 0;
                state.loading = false;
            })
            .addCase(FormAction.GetFormListing.rejected, (state, action) => {
                state.loading = false;
                state.data.formListing = [];
            })
            // Create Form
            .addCase(FormAction.CreateFormAction.pending, (state, action) => {
                state.data.createForm = [];
                state.loading = true;
            })
            .addCase(FormAction.CreateFormAction.fulfilled, (state, action) => {
                state.data.createForm = action?.payload?.data?.sources || [];
                state.loading = false;
            })
            .addCase(FormAction.CreateFormAction.rejected, (state, action) => {
                state.loading = false;
                state.data.createForm = [];
            })
            //GET Form
            .addCase(FormAction.getFormDetails.pending, (state, action) => {
                state.data.createForm = [];
                state.loading = true;
            })
            //  GET addContactsFields
            .addCase(
                ContactsActions.getAddContactsDropDown.pending,
                (state, action) => {
                    state.error = "";
                    state.loading = true;
                }
            )
            // action.payload?.data?.results;
            .addCase(
                ContactsActions.getAddContactsDropDown.fulfilled,
                (state, action) => {
                    state.data.addContactsDropDownList = action.payload.data?.data;
                }
            )
            .addCase(
                ContactsActions.getAddContactsDropDown.rejected,
                (state, action) => {
                    state.loading = false;
                    state.data.addContactsDropDownList = [];
                    state.error = action?.error?.message || "";
                }
            )
    }
})
export const { addFormFields, addSettings, clearFormStore, addDefaultValue, addDefaultFieldsId, addOptionsToShow, saveConditionLogicInEditFlow, removeOptionsToShow, deletePropertyFromDefaultObject, saveContactFields, saveFormConfig, addRow, saveFormName, removeRow, addFieldToRows, removeFieldFromRow, saveUpdatedFieldsPositions, setCustomPropertiesPreview, removePageRows, addFormPage, editField, setPreviewDevice, editingFieldObject, storepageFields, setAllFields, saveDroppedFieldsIds, deleteDroppedFieldsIds, setContactProperties } = formBuilderSlice.actions;

export default formBuilderSlice.reducer
